function abrirFecharPopupTermos(objeto, abrir) {
  encontrouDiv = false;

  while (!encontrouDiv) {
    objeto = objeto.parentElement;

    if (objeto.childNodes.length > 0) {
      for (let index = 0; index < objeto.childNodes.length; index++) {
        const element = objeto.childNodes[index];

        if (element.id == "modal-window-termos") {
          if (abrir) element.style.display = "block";
          else element.style.display = "none";

          encontrouDiv = true;
        }
      }
    }
  }
}

function abrirFecharPopupRegulamento(objeto, abrir) {
  encontrouDiv = false;
  while (!encontrouDiv) {
    objeto = objeto.parentElement;

    if (objeto.childNodes.length > 0) {
      for (let index = 0; index < objeto.childNodes.length; index++) {
        const element = objeto.childNodes[index];

        if (element.id == "modal-window-regulamento") {
          if (abrir) {
            element.style.display = "block";
          } else {
            element.style.display = "none";
          }

          for (
            let indexElement = 0;
            indexElement < element.__ngContext__.length;
            indexElement++
          ) {
            const elementPopup = element.__ngContext__[indexElement];

            if (
              elementPopup != null &&
              elementPopup != undefined &&
              elementPopup.hasOwnProperty("exibir")
            )
              elementPopup.__ngContext__[indexElement].exibir = abrir;
          }

          encontrouDiv = true;
        }
      }
    }
  }
}

function scrollControl() {
  var sidebar = $("#sidebar-desktop");
  if (sidebar.height() == null) return;

  // (+70) header | (+30) margin.
  var sidebarHeight = sidebar.height() + 70 + 30;

  var scrollHeight = $(window).scrollTop();

  var windowHeight = $(".container").height() - 335;

  var conteudoHeight = $(".cs_container-principal__direita").outerHeight();

  var scrollWindow = scrollHeight + windowHeight;
  var marginTop = scrollWindow - windowHeight - 55;

  // (+70) header | (+30) margin.
  var scrollMaxHeight = conteudoHeight + 70 + 100 - sidebarHeight;

  if (scrollHeight > 90 && scrollHeight < scrollMaxHeight) {
    sidebar.css({
      "margin-top": `${marginTop}px`,
    });
  } else if (scrollHeight >= scrollMaxHeight) {
    var margigTopCustom = conteudoHeight + 70 + 30 - sidebarHeight;

    var marginAtual =
      document.getElementById("sidebar-desktop").style.marginTop;

    if (marginAtual != `${margigTopCustom}px`) {
      sidebar.css({
        "margin-top": `${margigTopCustom}px`,
      });
    }
  } else {
    sidebar.css({
      "margin-top": "30px",
    });
  }
}

// Controle de scroll do menu lateral
// $(window).on("scroll", function() {
//     scrollControl();
// });

function abrirTermosPoliticaPrivacidade() {
  window.open(
    "https://www.cacaushow.com.br/politica-privacidade/privacy-policy.html",
    "_blank"
  );
}

function abrirFecharPopupAvisoHome(objeto, abrir) {
  encontrouDiv = false;

  while (!encontrouDiv) {
    objeto = objeto.parentElement;

    if (objeto.childNodes.length > 0) {
      for (let index = 0; index < objeto.childNodes.length; index++) {
        const element = objeto.childNodes[index];

        if (element.id == "modal-window-popupAvisoHome") {
          if (abrir) {
            element.style.display = "block";
          } else {
            element.style.display = "none";
          }

          for (
            let indexElement = 0;
            indexElement < element.__ngContext__.length;
            indexElement++
          ) {
            const elementPopup = element.__ngContext__[indexElement];

            if (
              elementPopup != null &&
              elementPopup != undefined &&
              elementPopup.hasOwnProperty("exibir")
            )
              elementPopup.__ngContext__[indexElement].exibir = abrir;
          }

          encontrouDiv = true;
        }
      }
    }
  }
}
